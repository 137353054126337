import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../assets/images/privacy-policy.jpg'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Privacy Policy" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Privacy Policy" 
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="privacy-policy-content">
                                <img src={privacyPolicy} alt="pp" />
                                <p><i>This Privacy Policy was last updated on January 1, 2023.</i></p>
                                <blockquote classname='blocquote'>
                                    <p>
                                        Your privacy is important to us. It is CODIZ's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you.
                                    </p>
                                    <br/>
                                    <p>
                                        Personal information is any information about you which can be used to identity you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use a website or online serivce.
                                    </p>
                                </blockquote>
                                <h3>1. Information We Collect</h3>
                                <p>
                                    Information we collect falls into one of two categories: "voluntarily provided" information and "automatically collected" information.
                                </p>
                                <p>
                                    "Voluntarrily provided" information refers to any information you knowingly and actively provide us when using or participating in any of our services and promotions.
                                </p>
                                <p>
                                    "Automatically collected" information refers to any information automatically sent to our system in the course of accessing our products and services.
                                </p>
                                <h3>2. Log Data</h3>
                                <p>
                                    When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details about your visit.
                                </p>
                                <p>
                                    Additionally, if you encounter certain errors while using the site, we may automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is.
                                </p>
                                <p>
                                Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.
                                </p>
                                <h3>3. Device Data</h3>
                                <p>
                                    When you visit our website or interact with our services, we may automatically collect data about your device, such as:
                                </p>
                                <ul>
                                    <li>
                                        Device Type
                                    </li>
                                    <li>
                                        Operating System
                                    </li>
                                    <li>
                                        Unique device identifiers
                                    </li>
                                    <li>
                                        Device settings
                                    </li>
                                    <li>
                                        Geo-location data
                                    </li>
                                </ul>
                                <p>
                                Data we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.
                                </p>
                                <h3>4. Personal Information</h3>
                                <p>
                                    We may ask for personal information -- for example, when you submit content to us or when you contact us, when you create account, and when you use our services  -- which may include one or more of the following:
                                    <ul>
                                        <li>Name</li>
                                        <li>Email</li>
                                        <li>Social media profiles</li>
                                        <li>Date of birth</li>
                                        <li>Phone/mobile number</li>
                                        <li>Home/mailing address</li>
                                        <li>Product details</li>
                                        <li>Orders</li>
                                        <li>Inventory</li>
                                        <li>Shipping Information</li>
                                    </ul>
                                </p>
                                <h3>5. User-Generated Content</h3>
                                <p>
                                    We consider "user-generated content" to be materials (text, image and/or video contet) voluntarily supplied to us by our users for the purpose of publication on our website or re-publishing on our social media channels. All user-generated content is associated with the account or email address used to submit the materials.
                                </p>
                                <p>
                                Please be aware that any content you submit for the purpose of publication will be public after posting (and subsequent review or vetting process). Once published, it may be accessible to third parties not covered under this privacy policy.
                                </p>
                                <h3>6. Legitimate Reasons for Processing Your Personal Information</h3>
                                <p>
                                We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you.
                                </p>
                                <h3>7. Collection and Use of Information</h3>
                                <p>
                                We may collect personal information from you when you do any of the following on our website:
                                <ul>
                                    <li>Register for an account</li>
                                    <li>Enter any of our competitions, contests, sweepstakes, and surveys</li>
                                    <li>Sign up to receive updates from us via email or social media channels</li>
                                    <li>Use a mobile device or web browser to access our content</li>
                                    <li>Contact us via email, social media, or on any similar technologies</li>
                                    <li>When you mention us on social media</li>
                                </ul>
                                </p>
                                <p>
                                We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes:
                                <ul>
                                    <li>to provide you with our platform’s core features and services</li>
                                    <li>to enable you to customize or personalize your experience of our website</li>
                                    <li>to deliver products and/or services to you</li>
                                    <li>to contact and communicate with you</li>
                                    <li>for analytics, market research, and business development, including to operate and improve our website, associated applications, and associated social media platforms</li>
                                    <li>for advertising and marketing, including to send you promotional information about our products and services and information about third parties that we consider may be of interest to you</li>
                                    <li>to enable you to access and use our website, associated applications, and associated social media platforms</li>
                                    <li>for internal record keeping and administrative purposes</li>
                                    <li>to run competitions, sweepstakes, and/or offer additional benefits to you</li>
                                    <li>to comply with our legal obligations and resolve any disputes that we may have</li>
                                    <li>to attribute any content (e.g. posts and comments) you submit that we publish on our website</li>
                                    <li>for security and fraud prevention, and to ensure that our sites and apps are safe, secure, and used in line with our terms of use</li>
                                    <li>for technical assessment, including to operate and improve our app, associated applications, and associated social media platforms</li>
                                </ul>
                                </p>
                                <p>
                                We may combine voluntarily provided and automatically collected personal information with general information or research data we receive from other trusted sources. For example, Our marketing and market research activities may uncover data and insights, which we may combine with information about how visitors use our site to improve our site and your experience on it.
                                </p>
                                <h3>8. Security of Your Personal Information</h3>
                                <p>
                                When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.
                                </p>
                                <p>
                                Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security.
                                </p>
                                <p>
                                You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services. For example, ensuring any passwords associated with accessing your personal information and accounts are secure and confidential.
                                </p>
                                <h3>9. How Long We Keep Your Personal Information</h3>
                                <p>
                                We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. For example, if you have provided us with personal information as part of creating an account with us, we may retain this information for the duration your account exists on our system. If your personal information is no longer required for this purpose, we will delete it or make it anonymous by removing all details that identify you.
                                </p>
                                <p>
                                However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.
                                </p>
                                <h3>10. Children’s Privacy</h3>
                                <p>
                                We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.
                                </p>
                                <h3>11. Disclosure of Personal Information to Third Parties</h3>
                                <p>
                                We may disclose personal information to third-party services for providing our services.
                                </p>
                                <p>
                                This may include:
                                <ul>
                                    <li>a parent, subsidiary, or affiliate of our company</li>
                                    <li>third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, hosting and server providers, ad networks, analytics, error loggers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors, and payment systems operators</li>
                                    <li>our employees, contractors, and/or related entities</li>
                                    <li>our existing or potential agents or business partners</li>
                                    <li>sponsors or promoters of any competition, sweepstakes, or promotion we run</li>
                                    <li>credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have provided to you</li>
                                    <li>courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights</li>
                                    <li>third parties, including agents or sub-contractors, who assist us in providing information, products, services, or direct marketing to you</li>
                                    <li>third parties to collect and process data</li>
                                    <li>an entity that buys, or to which we transfer all or substantially all of our assets and business</li>
                                </ul>
                                </p>
                                <h3>12. International Transfers of Personal Information</h3>
                                <p>
                                The personal information we collect is stored and/or processed in United States, Korea, and China, or where we or our partners, affiliates, and third-party providers maintain facilities.
                                </p>
                                <p>
                                The countries to which we store, process, or transfer your personal information may not have the same data protection laws as the country in which you initially provided the information. If we transfer your personal information to third parties in other countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information in accordance with this privacy policy.
                                </p>
                                <h3>13. Your Rights and Controlling Your Personal Information</h3>
                                <p>
                                <b>Your choice:</b> By providing personal information to us, you understand we will collect, hold, use, and disclose your personal information in accordance with this privacy policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of our website or the products and/or services offered on or through it.
                                </p>
                                <p>
                                    <b>Information from third parties:</b>If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.
                                </p>
                                <p>
                                    <b>Marketing permission:</b>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below.
                                </p>
                                <p>
                                    <b>Access:</b>You may request details of the personal information that we hold about you.
                                </p>
                                <p>
                                    <b>Correction:</b>If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.
                                </p>
                                <p>
                                    <b>Non-discrimination:</b>We will not discriminate against you for exercising any of your rights over your personal information. Unless your personal information is required to provide you with a particular service or offer (for example providing user support), we will not deny you goods or services and/or charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties, or provide you with a different level or quality of goods or services.
                                </p>
                                <p>
                                    <b>Downloading of Personal Information:</b>We provide a means for you to download the personal information you have shared through our site. Please contact us for more information.
                                </p>
                                <p>
                                    <b>Notification of data breaches:</b>We will comply with laws applicable to us in respect of any data breach.
                                </p>
                                <p>
                                    <b>Complaints:</b>If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.
                                </p>
                                <p>
                                    <b>Unsubscribe:</b>To unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using the details provided in this privacy policy, or opt-out using the opt-out facilities provided in the communication. We may need to request specific information from you to help us confirm your identity.
                                </p>
                                <h3>14. Use of Cookies</h3>
                                <p>
                                    We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified.
                                </p>
                                <h3>15. Business Transfers</h3>
                                <p>
                                    If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data, including your personal information, among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may, to the extent permitted by applicable law, continue to use your personal information according to this policy, which they will be required to assume as it is the basis for any ownership or use rights we have over such information.
                                </p>
                                <h3>16. Limits of Our Policy</h3>
                                <p>
                                    Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.
                                </p>
                                <h3>17. Changes to This Policy </h3>
                                <p>
                                    At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy.
                                </p>
                                <p>
                                    If the changes are significant, or if required by applicable law, we will contact you (based on your selected preferences for communications from us) and all our registered users with the new details and links to the updated or changed policy.
                                </p>
                                <p>
                                    If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.
                                </p>
                                <h3>18. Additional Disclosures for General Data Protection Regulation (GDPR) Compliance (EU)</h3>
                                <h5>Data Controller / Data Processor</h5>
                                <p>
                                    The GDPR distinguishes between organisations that process personal information for their own purposes (known as “data controllers”) and organizations that process personal information on behalf of other organizations (known as “data processors”). We, CODIZ, located at the address provided in our Contact Us section, are a Data Controller and/or Processor with respect to the personal information you provide to us.
                                </p>
                                <h5>Legal Bases for Processing Your Personal Information</h5>
                                <p>
                                    We will only collect and use your personal information when we have a legal right to do so. In which case, we will collect and use your personal information lawfully, fairly, and in a transparent manner. If we seek your consent to process your personal information, and you are under 16 years of age, we will seek your parent or legal guardian’s consent to process your personal information for that specific purpose.
                                </p>
                                <p>
                                    Our lawful bases depend on the services you use and how you use them. This means we only collect and use your information on the following grounds:
                                </p>
                                <h5>Consent From You</h5>
                                <p>
                                    Where you give us consent to collect and use your personal information for a specific purpose. You may withdraw your consent at any time using the facilities we provide; however this will not affect any use of your information that has already taken place. You may consent to providing your name and contact details for the purpose of entering a giveaway or promotion. While you may withdraw your entry at any time, this will not affect any selection or judging that has already taken place. If you have any further enquiries about how to withdraw your consent, please feel free to enquire using the details provided in the Contact Us section of this privacy policy.
                                </p>
                                <h5>Performance of a Contract or Transaction</h5>
                                <p>
                                    Where you have entered into a contract or transaction with us, or in order to take preparatory steps prior to our entering into a contract or transaction with you. For example, if you contact us with an enquiry, we may require personal information such as your name and contact details in order to respond.
                                </p>
                                <h5>Our Legitimate Interests</h5>
                                <p>
                                    Where we assess it is necessary for our legitimate interests, such as for us to provide, operate, improve and communicate our services. We consider our legitimate interests to include research and development, understanding our audience, marketing and promoting our services, measures taken to operate our services efficiently, marketing analysis, and measures taken to protect our legal rights and interests.
                                </p>
                                <h5>Compliance with Law</h5>
                                <p>
                                In some cases, we may have a legal obligation to use or keep your personal information. Such cases may include (but are not limited to) court orders, criminal investigations, government requests, and regulatory obligations. If you have any further enquiries about how we retain personal information in order to comply with the law, please feel free to enquire using the details provided in the Contact Us section of this privacy policy.
                                </p>
                                <h3>19. Contact Us</h3>
                                <p>
                                    For any questions or concerns regarding your privacy, you may contact us using the following details:
                                </p>
                                <p>
                                    CODIZ<br/>
                                    contact@codizsoft.com
                                </p>





                                
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                Purchase Guide
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                Terms of Service
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="widget widget_recent_courses">
                                    <h3 className="widget-title">Recent Courses</h3>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg1" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$49.00</span>
                                            <h4 className="title usmall"><Link to="#">The Data Science Course 2020: Complete Data Science Bootcamp</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$59.00</span>
                                            <h4 className="title usmall"><Link to="#">Java Programming MasterclassName for Software Developers</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$69.00</span>
                                            <h4 className="title usmall"><Link to="#">Deep Learning A-Z™: Hands-On Artificial Neural Networks</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>
                                </div>

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                        <Link to="#">Business <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Design <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Braike <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Fashion <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Travel <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Smart <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Marketing <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Tips <span className="tag-link-count"> (2)</span></Link>
                                    </div>
                                </div>
                            </aside>
                        </div> */}
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;